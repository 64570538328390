<template>
  <master-layout>

    <ion-card>
      <ion-card-content>
        <img src="assets/images/profil-logged-out-illustration.svg" class="illustration"/>
        <h1>A mentéshez fiók szükséges</h1>
        <p class="pCenter marginBottom">
          <br/>
          Ez nem vesz igénybe többet néhány percnél, cserébe az app funkcióinak bővülésével számtalan új lehetőség nyílik meg előtted!
        </p>
        <ion-button shape="round" expand="block" color="primary" @click="$router.push('/regisztracio')">Regisztrálok</ion-button>
        <ion-button expand="block" fill="transparent" @click="$router.push('/login')">Már van fiókom</ion-button>
        <ion-button expand="block" fill="transparent" color="secondary" @click="$router.go(-1)">Mégsem</ion-button>
      </ion-card-content>
    </ion-card>
    <div class="bottomSpacer"></div>

  </master-layout>
</template>

<script>
import {
  IonCard, IonCardContent, IonButton
} from '@ionic/vue'

export default {
  components: {
    IonCard, IonCardContent, IonButton
  }
}
</script>

<style scoped>
ion-button{
  margin: 10px 0;
}
p.marginBottom{
  margin-bottom: 40px;
}
.illustration{
  display: block;
  width: 60%;
  margin: 40px auto 20px auto;
}
</style>
